import validate from "/home/josed/Documents/CODE/relowcode/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import test_45global from "/home/josed/Documents/CODE/relowcode/app/middleware/test.global.ts";
import client_45auth from "/home/josed/Documents/CODE/relowcode/app/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/client-auth.mjs";
import manifest_45route_45rule from "/home/josed/Documents/CODE/relowcode/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  test_45global,
  client_45auth,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/josed/Documents/CODE/relowcode/app/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/auth.mjs"),
  "guest-only": () => import("/home/josed/Documents/CODE/relowcode/app/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/guest-only.mjs")
}