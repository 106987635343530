import { ThemeDefinition } from "vuetify";
console.log("holaaaaaaa")
console.log(twColors.blue[500])
// String that represents the name of the theme I am using
export const LIGHT_THEME = "light";
// Light mode theme
export const light: ThemeDefinition = {
  dark: false,
  colors: {
    // background:   "#FFFFFF",//"#ddf3ff",
    surface:   "#F7F7F7",//"#ddf3ff",
    "surface-variant": "#000000",
    "on-surface-variant": "#FFFFFF",
    background: "#E8E8E8",
    primary: "#04062F",
    secondary: "#7F85F5",
    error: twColors.red[500],
    info: twColors.blue[500],
    success: twColors.emerald[500],
    warning: twColors.amber[500],
  },
};

// String that represents the name of the dark theme I am using
export const DARK_THEME = "dark";
// Dark mode theme
export const dark: ThemeDefinition = {
  dark: true,
  colors: {
    background: "#000000",
    "surface-variant": "#000000",
    "on-surface-variant": "#FFFFFF",
    surface: "#04062F",
    primary:  "#ddf3ff",
    secondary:"#ec47ac",
    error: twColors.red[500],
    info: twColors.blue[500],
    success: twColors.emerald[500],
    warning: twColors.amber[500],
  },
};