import { default as all_45templatesetuaMBAzwkMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/all-templates.vue?macro=true";
import { default as classesaWbYrPgCExMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/classes.vue?macro=true";
import { default as crud0A2X2tGTxZMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/crud.vue?macro=true";
import { default as editork2jKbA7WCaMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/editor.vue?macro=true";
import { default as general_45_91_91template_93_93eSJZLlrDoDMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/general-[[template]].vue?macro=true";
import { default as loginHDu8UeEcgzMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/login.vue?macro=true";
import { default as pagesyBnhlDYMGPMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/pages.vue?macro=true";
import { default as publishZl2qg1RbiyMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/publish.vue?macro=true";
import { default as styles_45old4EPYauyUg0Meta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/styles-old.vue?macro=true";
import { default as stylesU0EOc43yryMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/styles.vue?macro=true";
import { default as tablesl7K0osilqOMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/tables.vue?macro=true";
import { default as index5t9hM9Ikm5Meta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/index.vue?macro=true";
import { default as myaccountj72xUK7EnEMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/myaccount.vue?macro=true";
import { default as newnQCimo5ov2Meta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/new.vue?macro=true";
import { default as settingspt7oRkOSq9Meta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/settings.vue?macro=true";
import { default as skvM8mX7HVaDMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/sk.vue?macro=true";
import { default as editkqZQZMOSQKMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/template-[id]/edit.vue?macro=true";
import { default as index4ce98OViqiMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/app/template-[id]/index.vue?macro=true";
import { default as indexMgDktTgXmZMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/index.vue?macro=true";
import { default as index2z30iCGBVG2Meta } from "/home/josed/Documents/CODE/relowcode/app/pages/index2.vue?macro=true";
import { default as index3ba7TBXJhLgUMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/index3b.vue?macro=true";
import { default as loginUV6ZJfB424Meta } from "/home/josed/Documents/CODE/relowcode/app/pages/login.vue?macro=true";
import { default as logixxmTP9FUpM5mMeta } from "/home/josed/Documents/CODE/relowcode/app/pages/logixx.vue?macro=true";
import { default as otraxNOs2S9cU3Meta } from "/home/josed/Documents/CODE/relowcode/app/pages/otra.vue?macro=true";
export default [
  {
    name: "app-all-templates",
    path: "/app/all-templates",
    meta: all_45templatesetuaMBAzwkMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/all-templates.vue").then(m => m.default || m)
  },
  {
    name: "app-app-id-classes",
    path: "/app/app-:id()/classes",
    meta: classesaWbYrPgCExMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/classes.vue").then(m => m.default || m)
  },
  {
    name: "app-app-id-crud",
    path: "/app/app-:id()/crud",
    meta: crud0A2X2tGTxZMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/crud.vue").then(m => m.default || m)
  },
  {
    name: "app-app-id-editor",
    path: "/app/app-:id()/editor",
    meta: editork2jKbA7WCaMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/editor.vue").then(m => m.default || m)
  },
  {
    name: "app-app-id-general-template",
    path: "/app/app-:id()/general-:template?",
    meta: general_45_91_91template_93_93eSJZLlrDoDMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/general-[[template]].vue").then(m => m.default || m)
  },
  {
    name: "app-app-id-login",
    path: "/app/app-:id()/login",
    meta: loginHDu8UeEcgzMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/login.vue").then(m => m.default || m)
  },
  {
    name: "app-app-id-pages",
    path: "/app/app-:id()/pages",
    meta: pagesyBnhlDYMGPMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/pages.vue").then(m => m.default || m)
  },
  {
    name: "app-app-id-publish",
    path: "/app/app-:id()/publish",
    meta: publishZl2qg1RbiyMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/publish.vue").then(m => m.default || m)
  },
  {
    name: "app-app-id-styles-old",
    path: "/app/app-:id()/styles-old",
    meta: styles_45old4EPYauyUg0Meta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/styles-old.vue").then(m => m.default || m)
  },
  {
    name: "app-app-id-styles",
    path: "/app/app-:id()/styles",
    meta: stylesU0EOc43yryMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/styles.vue").then(m => m.default || m)
  },
  {
    name: "app-app-id-tables",
    path: "/app/app-:id()/tables",
    meta: tablesl7K0osilqOMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/app-[id]/tables.vue").then(m => m.default || m)
  },
  {
    name: "app",
    path: "/app",
    meta: index5t9hM9Ikm5Meta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: "app-myaccount",
    path: "/app/myaccount",
    meta: myaccountj72xUK7EnEMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/myaccount.vue").then(m => m.default || m)
  },
  {
    name: "app-new",
    path: "/app/new",
    meta: newnQCimo5ov2Meta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/new.vue").then(m => m.default || m)
  },
  {
    name: "app-settings",
    path: "/app/settings",
    meta: settingspt7oRkOSq9Meta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/settings.vue").then(m => m.default || m)
  },
  {
    name: "app-sk",
    path: "/app/sk",
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/sk.vue").then(m => m.default || m)
  },
  {
    name: "app-template-id-edit",
    path: "/app/template-:id()/edit",
    meta: editkqZQZMOSQKMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/template-[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-template-id",
    path: "/app/template-:id()",
    meta: index4ce98OViqiMeta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/app/template-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index2",
    path: "/index2",
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/index2.vue").then(m => m.default || m)
  },
  {
    name: "index3b",
    path: "/index3b",
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/index3b.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginUV6ZJfB424Meta || {},
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logixx",
    path: "/logixx",
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/logixx.vue").then(m => m.default || m)
  },
  {
    name: "otra",
    path: "/otra",
    component: () => import("/home/josed/Documents/CODE/relowcode/app/pages/otra.vue").then(m => m.default || m)
  }
]