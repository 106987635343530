import VueGoogleMaps from '@fawmi/vue-google-maps/src/main.js'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  nuxtApp.vueApp.use(VueGoogleMaps, {
    load: {
      key: config.public.map.GOOGLE_MAPS_KEY,
      v: 'quarterly'
    },
  })
})