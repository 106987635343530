import { createVuetify } from "vuetify";
import * as components from 'vuetify/components'
import {VBtn,VTextField, VSelect, VAutocomplete} from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labsComponents from 'vuetify/labs/components'

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    components:{
      ...components, ...labsComponents
    },
    directives,
    // date: {
    //   adapter: VuetifyDateAdapter,
    // },
    ssr: true,
    aliases: {
      VBtnPrimary: VBtn,
      VBtnSecondary: VBtn,
      VBtnTertiary: VBtn,
    },
    defaults: {
      VBtnPrimary: {
        color: 'primary',
        // bg: 'blue',
        variant: 'flat',
        rounded: "0"
      },
      VBtnSecondary: {
        color: 'secondary',
        variant: 'flat',
        rounded: "0"
      },
      VBtnTertiary: {
        variant: 'plain',
        rounded: "0"
      },
      VTextField:{
        variant: 'outlined',
        rounded: "0"
      },
      VAutocomplete:{
        variant: 'outlined',
        rounded: "0"
      },
      VTextarea:{
        variant: 'outlined',
        rounded: "0"
      },
      VSelect:{
        variant: 'outlined',
        rounded: "0"
      }
    },
    theme: {
        defaultTheme: LIGHT_THEME,
        themes: {
          light,
          dark,
        },
      },
      // Add the custom iconset
      icons: {
        defaultSet: "custom",
        aliases,
        sets: {
          custom,
        },
    },
  })

  nuxtApp.vueApp.use(vuetify)
})



// export default defineNuxtPlugin((app) => {
//   const vuetify = createVuetify({
//     ssr: true,
//     aliases: {
//       VBtnSecondary: VBtn,
//       VBtnTertiary: VBtn,
//     },
//     defaults: {
//       VBtn: {
//         color: 'primary',
//         bg: 'blue',
//         variant: 'flat',
//       },
//       VBtnSecondary: {
//         color: 'secondary',
//         variant: 'flat',
//       },
//       VBtnTertiary: {
//         rounded: true,
//         variant: 'plain',
//       },
//     },
//     // add theme
//     theme: {
//       defaultTheme: LIGHT_THEME,
//       themes: {
//         light,
//         dark,
//       },
//     },
//     // Add the custom iconset
//     icons: {
//       defaultSet: "custom",
//       aliases,
//       sets: {
//         custom,
//       },
//     },
//   });

//   app.vueApp.use(vuetify);
// });